.testimonial-container {
	--testimonial-quote-count: 3;
	@include responsive($breakpoint-large-desktop, min) {
		--testimonial-quote-count: 3;
	}
	@include responsive($breakpoint-large-desktop) {
		--testimonial-quote-count: 2;
	}
	@include responsive($breakpoint-tablet) {
		--testimonial-quote-count: 1;
	}

	text-align: center;

	h2 {
		margin-bottom: 3em;
		position: relative;
	}

	display: flex;
	justify-content: center;
	padding: 0;

	position: relative;

	.carousel-btn {
		$button-size: 3rem;
		position: absolute;
		top: calc(50% - (#{$button-size} / 2));
		background-color: transparent;
		color: $color-primary;
		border: none;
		cursor: pointer;
		z-index: 12;

		width: $button-size;
		height: $button-size;

		svg {
			height: 100%;
			width: 100%;
		}
		padding: 0;

		@include active-state {
			@include animate-pulse;
		}

		&.prev-btn {
			right: 100%;
		}
		&.next-btn {
			left: 100%;
		}
	}

	$column-gap: $page-spacing * 2;

	.testimonial-carousel {
		display: flex;
		overflow-x: auto;
		scroll-behavior: smooth;
		-ms-overflow-style: none;
		scrollbar-width: none;
		gap: $column-gap;
		position: relative;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.testimonial-item {
		flex: 0 0
			calc((100% - (#{$column-gap} * (var(--testimonial-quote-count) - 1))) / var(--testimonial-quote-count));
		flex-direction: column;
		padding: 1rem $page-spacing 0; // top-padding to accommodate for no stars
		gap: $page-spacing;
		display: flex;

		> * {
			margin: 0;
		}

		// Stars have been temporarily removed from the DOM
		.testimonial-stars {
			// display: flex;
			// justify-content: center;
			// gap: 0.75em;
			// svg {
			// 	color: $color-blue-light;
			// 	height: 1.75em;
			// }
		}

		.testimonial-text {
			font-size: 1rem;
		}

		.testimonial-author {
			font-size: 0.9rem;
			.name {
				font-weight: 700;
				letter-spacing: 0.03em;
			}
			.location {
				padding-left: 0.15em;
			}
		}
	}

	@include responsive($breakpoint-mobile) {
		display: block;
		.carousel-btn {
			position: initial;
			margin: 0 $page-spacing * 0.5;
		}
	}
}
