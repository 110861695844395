h1,
h2 {
	font-family: $font-display-bold-family;
	font-weight: $font-display-bold-weight;
	letter-spacing: $font-body-bold-spacing;

	margin: 1.5em 0;

	&:first-child {
		margin-top: 0;
	}
}

h3,
h4,
h5,
h6 {
	font-family: $font-display-normal-family;
	font-weight: 600;
}

h1 {
	font-size: $font-size-xxxl;
}

h2 {
	font-size: $font-size-xxl;
}

h3 {
	font-size: $font-size-xl;
}

h4 {
	font-size: $font-size-lg;
}

h5 {
	font-size: $font-size-md;
}

h6 {
	font-size: $font-size-sm;
}

@include responsive($breakpoint-mobile, max) {
	h1 {
		font-size: $font-size-xxl;
	}

	h2 {
		font-size: $font-size-xl;
	}

	h3 {
		font-size: $font-size-lg;
	}

	h4 {
		font-size: $font-size-lg;
	}

	h5 {
		font-size: $font-size-md;
	}

	h6 {
		font-size: $font-size-md;
	}
}

// -----------------------------------------
// General text
// -----------------------------------------

p,
li,
td {
	font-weight: 500;
	line-height: 2em;
}

p,
td {
	font-size: 0.95rem;
}

p {
	margin-bottom: 1.75em;
}

li,
ol {
	margin-bottom: 0.4em;
	font-size: 0.9rem;
	&:last-child {
		margin-bottom: 0;
	}
	p {
		font-size: 0.9rem;
		&:last-of-type {
			margin-bottom: 0;
		}
		&:first-of-type {
			margin-top: 0;
		}
	}

	ul,
	ol {
		margin-bottom: 0.25em;
	}
}

.emphasised-text {
	color: $color-magenta;
}
.no-list-style {
	ol,
	ul {
		list-style-type: none;
	}
}
.underline-headings {
	h2 {
		border-bottom: 2px solid $color-primary;
		padding-bottom: 0.75em;
		margin-top: 2.5em;
	}
}

// -----------------------------------------
// Links
// -----------------------------------------

a {
	position: relative;
	word-break: auto-phrase;
	overflow-wrap: normal;
	&:after {
		content: '';
		display: block;
		position: absolute;
		width: 0;
		left: 0;
		bottom: -0.5rem;
		border-bottom: 1.5px solid $color-text-dark;
		transition: width 0.5s ease-in-out;
	}
	&.no-underline {
		text-decoration: none;
		&:after {
			display: none;
		}
	}
	@include active-state {
		cursor: pointer;
		&:after {
			width: 100%;
		}
	}
}

.dark-bg {
	color: $color-text-light;
	border-color: $color-text-light;
	a:after {
		border-color: $color-text-light;
	}
}

.switch-link-underline-direction a,
.switch-link-underline-direction {
	&:after {
		right: 0;
		left: unset;
	}
}

.light-bg,
.dark-bg {
	p a {
		color: $color-accent-1;
		&:after {
			border-color: $color-accent-1;
		}
	}
}
