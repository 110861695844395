.home-page {
	section {
		padding: $page-spacing * 4 $page-spacing * 3;
		width: 100dvw;
		max-width: 100%;
		@include responsive($breakpoint-large-desktop, min) {
			// padding: $page-spacing * 6 $page-spacing * 5;
		}
		@media (max-width: $breakpoint-tablet) {
			padding: $page-spacing * 3 $page-spacing * 2;
		}
		@media (max-width: $breakpoint-mobile) {
			padding: $page-spacing * 2 $page-spacing * 1;
		}
	}

	#welcome-section {
		position: relative;
		z-index: $index-above-navbar;
		max-height: 100dvh;
		min-height: 100dvh;
		overflow-y: hidden;

		display: grid;
		grid-template-rows: 2fr 1fr;
		align-items: center;

		padding-bottom: $page-spacing * 4;

		--logo-width: 300px;

		// ----- Login / sign-up button -----
		.auth-links {
			position: absolute;
			top: $page-spacing;
			left: $page-spacing;

			display: flex;
			align-items: center;
			gap: 0.5rem;
		}

		// ---- Logo -----
		.logo-lrg {
			position: absolute;
			bottom: $page-spacing;
			right: $page-spacing * 1.25;
			max-width: var(--logo-width);
			height: auto;
		}

		// ----- Welcome -----
		h1 {
			font-size: 4em;
			margin: 0;
		}

		// ----- Question container -----
		h2 {
			margin-bottom: 5rem;
			@media (max-width: $breakpoint-tablet) {
				text-align: center;
				padding: 0 40px 30px;
			}

			@media (max-width: $breakpoint-mobile) {
				align-items: flex-start;
				text-align: center;
				padding: 30px 40px;
				margin-top: 100px;
			}
		}
		.answers {
			z-index: 1;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 2em;

			a {
				font-size: 1.1rem;
				padding-bottom: 0.5em;

				&:after {
					border-width: 2px;
				}
			}
		}

		// --------------------------------------
		// --------- Responsive styling ---------
		// --------------------------------------

		// Hiding the desktop-only elements when it hits tablet
		@include responsive($breakpoint-tablet) {
			.desktop-only {
				display: none;
			}
		}
		// Hiding the mobile-only elements when it is larger than tablet
		@include responsive($breakpoint-tablet, min) {
			.mobile-only {
				display: none;
			}
		}

		@include responsive($breakpoint-tablet) {
			z-index: 0; // Moving the menu to be on top
			padding-top: calc(var(--navbar-height_approx) + #{$page-spacing});
			padding-bottom: $page-spacing * 2;
			text-align: center;

			// Aligning the heading content
			grid-template-rows: 2fr 1fr auto;
			row-gap: $page-spacing;
			h1 {
				align-self: flex-end;
			}
			.welcome-subtitle {
				align-self: flex-start;
				line-height: 1.6;
			}

			// Styling the answers
			h2 {
				margin: 0 auto $page-spacing * 1.5;
				padding: 0;

				font-size: 1.5rem;
			}
			.answers {
				align-items: center;
				a {
					border: 2px solid $color-text-light;
					padding: 1.5em 1em;
					width: 100%;
					max-width: 25rem;
					font-size: 1rem;
					&:after {
						content: unset;
					}
					@include active-state {
						background-color: transparentize($color-text-light, 0.8);
					}
				}
			}
		}

		// Updating the padding and font-size on mobile devices
		@include responsive($breakpoint-mobile) {
			padding-bottom: $page-spacing;
			.answers {
				gap: 1.5rem;
				a {
					font-size: 0.9rem;
				}
			}
		}

		// Vertical styles to adjust text sizing
		@include responsive(48rem, max, height) {
			// When it is tablet styling
			@include responsive($breakpoint-tablet) {
				padding-bottom: $page-spacing;
				padding-top: var(--navbar-height_approx);
				row-gap: $page-spacing * 0.75;
				h1 {
					font-size: 2.75rem;
				}
				.welcome-subtitle {
					font-size: 0.8rem;
				}
				h2 {
					margin-bottom: $page-spacing;
					font-size: 1.3rem;
				}
				.answers {
					gap: 1rem;
					a {
						padding: 1.15em 1.5em;
						font-size: 0.9rem;
					}
				}
				max-height: unset;
			}
			// When it is desktop styling
			@include responsive($breakpoint-tablet, min) {
				padding: $page-spacing * 3 $page-spacing * 3;
				font-size: 0.7rem;

				h2 {
					margin-bottom: 1.1em;
				}
				.answers a {
					font-size: 1.3em;
					padding: 0;
				}
			}
		}
	}

	.masked-section {
		--mask-image-base-width: 25dvw;
		--masked-section-height: calc(90dvh - var(--navbar-height_approx));

		@include wave-mask-section;

		height: var(--masked-section-height);

		// -----------------------------------
		// Image masking + styles
		// -----------------------------------
		@include responsive($masked-section-breakpoint, min) {
			.mask-container {
				@include wave-mask-container;
			}
			@include set-wave-image-masks;
			&.mask-right {
				grid-template-columns: 1fr auto;
				.mask-container {
					width: calc(var(--mask-image-base-width) * 2);
				}
			}
			&.mask-both {
				grid-template-columns: auto 1fr auto;
				.mask-container {
					width: var(--mask-image-base-width);
				}
			}
			&.mask-left {
				grid-template-columns: auto 1fr;
				.mask-container {
					width: calc(var(--mask-image-base-width) * 2);
				}
			}
		}

		// -----------------------------------
		// Content
		// -----------------------------------
		.content-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;

			a.link {
				font-weight: bold;
				font-size: 0.95rem;
				margin-top: 1.5em;
			}
		}
		--content-padding-vertical: #{$page-spacing * 2};
		--content-padding-gap: #{$page-spacing};
		--content-padding-horizontal: #{$page-spacing * 2};
		&.mask-right {
			.content-container {
				padding: var(--content-padding-vertical) var(--content-padding-gap) var(--content-padding-vertical)
					var(--content-padding-horizontal);
			}
		}
		&.mask-both {
			.content-container {
				padding: var(--content-padding-vertical) var(--content-padding-gap);
			}
		}
		&.mask-left {
			.content-container {
				padding: var(--content-padding-vertical) var(--content-padding-horizontal)
					var(--content-padding-vertical) var(--content-padding-gap);
			}
		}

		// -----------------------------------
		// Responsiveness
		// -----------------------------------
		@include responsive($breakpoint-desktop, min) {
			--content-padding-gap: #{$page-spacing * 2};
			--content-padding-horizontal: #{$page-spacing * 2};
			@include responsive(55rem, max, height) {
				--masked-section-height: calc(95dvh - var(--navbar-height_approx));
				--mask-image-base-width: 15dvw;
			}
		}
		@include responsive($breakpoint-tablet) {
			--mask-image-base-width: 18dvw;
		}
		@include responsive($masked-section-breakpoint, min) {
			@include responsive(57rem, max, height) {
				--masked-section-height: auto;
				--content-padding-vertical: #{$page-spacing * 4};
				.mask-container {
					height: 100%;
				}
			}
		}
		@include responsive($masked-section-breakpoint) {
			display: block;
			position: relative;
			height: unset;
			.mask-container {
				position: absolute;
				width: 100%;
				height: 100%;
				background-size: cover;
				top: 0;
				left: 0;
			}
			.content-container {
				position: relative;
				z-index: 1;
				--content-padding-gap: var(--content-padding-horizontal);
				--content-padding-vertical: #{$page-spacing * 3};
			}
			&.light-bg {
				.content-container {
					background-color: transparentize($color-white, 0.25);
				}
			}
			&.dark-bg {
				.content-container {
					background-color: transparentize($color-primary, 0.15);
				}
			}
		}
		@include responsive($breakpoint-mobile) {
			// .content-container {
			--content-padding-gap: var(--content-padding-horizontal);
			--content-padding-horizontal: #{$page-spacing};
			--content-padding-vertical: #{$page-spacing * 2};
			// }
		}
	}

	.about-possums {
		.emphasised-text {
			font-weight: 700;
			font-style: italic;
			color: unset;
		}
	}

	.research-section {
		h2 {
			margin-bottom: 1em;
		}
		p {
			margin-bottom: 0;
		}

		.quote-container {
			width: calc(100% - 4rem);
			margin-block-start: 1em;
			margin-block-end: 1em;
			margin-bottom: 0.5em;
		}
	}

	.loved-section {
		.quote-container {
			width: 100%;
			margin-top: 0;
			margin-bottom: $page-spacing * 1.5;
		}
		.quote-container,
		.quote-style,
		.quote-source {
			font-size: 0.9rem;
		}

		@include responsive($breakpoint-mobile) {
			.testimonials {
				padding: 0 $page-spacing * 0.5;
			}
		}
	}
}
