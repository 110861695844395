.Modal {
	.inner {
		top: calc(50% + var(--navbar-height_approx) / 2);
		transform: translate(-50%, -50%);
		min-width: min(320px, 100vw);
		max-width: min(860px, 100vw);
		max-height: calc(95vh - var(--navbar-height_approx));
	}

	&.blur {
		.overlay {
			backdrop-filter: blur(8px);
			background: rgba($color-white, 0%);
		}
	}

	&.popup-overlay {
		.content {
			border-width: 8px;
			padding: 4rem;

			@include responsive($breakpoint-mobile) {
				padding: 2rem;
			}

			@include responsive($breakpoint-mobile, min, height) {
				padding: 2rem;
			}
		}
		.header {
			border: none;
			padding: 0;
			margin: $spacing-xxxl 0;

			@include responsive($breakpoint-mobile) {
				margin: $spacing-lg 0;
			}
			@include responsive($breakpoint-mobile, min, height) {
				margin: $spacing-lg 0;
			}
		}
		h4 {
			font-size: $font-size-xl;
			text-align: center;
		}
	}

	.SubscriptionBenefits {
		max-width: 480px;
		margin: 0 auto;
		padding-top: 2rem;

		@include responsive($breakpoint-mobile) {
			padding: 0;
		}

		@include responsive($breakpoint-mobile, min, height) {
			padding: 0;
		}
	}
}

#newsletter-signup {
	p {
		padding: 0;
		margin: 0;
	}

	.footnote {
		text-align: center;
		width: 100%;
		max-width: 480px;
		margin: 3rem auto 0 auto;
		font-size: $font-size-sm;
	}

	@include responsive($breakpoint-mobile) {
		.footnote {
			margin: 1rem auto 0 auto;
		}
	}

	.NewsletterSignup > form {
		margin: $spacing-xxxl 0;
		display: grid;
		gap: $spacing-xxxl;
		grid-template-columns: 1fr 2fr;
		grid-template-areas:
			'name email'
			'submit submit';

		[data-control-name='name'] {
			grid-area: name;
		}

		[data-control-name='email'] {
			grid-area: email;
		}

		.Button[type='submit'] {
			grid-area: submit;
			justify-self: center;
			@include set-button-size(large);
		}

		@include responsive($breakpoint-mobile) {
			grid-template-columns: 1fr;
			gap: $spacing-lg;
			grid-template-areas:
				'name'
				'email'
				'submit';

			.Button.large-button {
				min-width: unset;
			}
		}
	}
}

#free-limit-reached {
	p {
		text-align: center;

		&:first-child {
			margin-top: 0;
		}
	}
}

#subscription-options,
#add-subscription,
#subscription-error-with-options {
	.SubscriptionComparison {
		.continue-with-selection {
			display: flex;
			flex-direction: column;

			> div {
				display: flex;
				justify-content: center;
				margin: $spacing-xxl;

				// Needs to be another 100px before the $breakpoint-mobile, because the table can't collapse that small
				@include responsive(calc($breakpoint-mobile + 100px)) {
					margin: $spacing-sm;
				}
			}

			> label {
				display: flex;
				align-items: flex-start;

				> p {
					margin: 0;

					&.tal {
						text-align: left;
					}
					> a {
						font-weight: bold;
						&:after {
							display: none;
						}
					}
				}
			}
		}
	}
	.checkout-details {
		p {
			text-align: center;
			margin: 0;
		}

		.SignupForm {
			margin-top: 3rem;

			> .Form {
				width: 640px;
				max-width: 100%;
				display: grid;
				gap: $spacing-xxl;
				grid-template-columns: 1fr 1fr;
				grid-template-areas:
					'fullName email'
					'password confirmPassword'
					'billing billing'
					'buttons buttons';

				[data-control-name='fullName'] {
					grid-area: fullName;
				}
				[data-control-name='email'] {
					grid-area: email;
				}
				[data-control-name='password'] {
					grid-area: password;
				}
				[data-control-name='confirmPassword'] {
					grid-area: confirmPassword;
				}
				[data-control-name='_billingAddress'] {
					grid-area: billing;
				}

				> .billing-address {
					grid-area: billing;

					> .address {
						margin: $spacing-sm 0;
					}
				}

				> .buttons {
					grid-area: buttons;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: $spacing-xxxl;

					> .Button[type='submit'] {
						@include set-button-size(large);
					}
				}

				@include responsive($breakpoint-mobile) {
					grid-template-columns: 1fr;
					grid-template-areas:
						'fullName'
						'email'
						'password'
						'confirmPassword'
						'billing'
						'buttons';

					> .buttons {
						flex-direction: column-reverse;
						gap: $spacing-xxl;

						> .Button[type='submit'] {
							min-width: unset;
						}
					}

					> .billing-address {
						> .address {
							margin: $spacing-md 0;
						}
					}
				}
			}

			&.user-prefilled {
				> .Form {
					grid-template-areas:
						'billing billing'
						'buttons buttons';
				}
			}
		}
	}

	.payment-details {
		> p:first-child {
			margin: 0;

			> span {
				display: block;
				margin-top: $spacing-sm;
			}
		}

		.gst {
			font-size: $font-size-sm;
			text-align: right;
		}

		.StripePaymentForm {
			margin: 3rem 0;

			@include responsive($breakpoint-mobile) {
				margin-bottom: 1rem;
			}
		}

		.buttons {
			display: flex;
			gap: $spacing-lg;

			@include responsive($breakpoint-mobile) {
				flex-wrap: wrap;
				justify-content: center;
				gap: $spacing-lg;
			}

			.pay-now {
				flex-grow: 0;
				flex-shrink: 0;
				> * {
					flex-grow: 0;
					flex-shrink: 0;
				}
			}
		}
	}
}

#subscription-details {
	.description {
		white-space: pre-wrap;
	}
	.details {
		> span {
			display: block;
		}
	}
}

#welcome {
	.header {
		h4 {
			@include responsive($breakpoint-mobile) {
				font-size: $font-size-md;
			}
		}
	}
}
