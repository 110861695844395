body.menu-open {
	overflow: hidden;
}

$search-bar-bg: $color-blue-mid;

body.search-open {
	@include responsive($breakpoint-tablet, min) {
		.search-form {
			display: block;
		}
		.toggle-search {
			&:before {
				content: '';
			}
		}
	}
	@include responsive($breakpoint-tablet) {
		.search-form {
			display: flex;
		}
	}
}

// If you change any part of this, make sure you check the --navbar-height_approx variable in the general file is still accurate
$menu-padding-top: $page-spacing * 0.6;
$menu-padding-sides: $page-spacing * 0.75;
$item-gap: $menu-padding-sides * 2;

.navbar {
	position: fixed;
	z-index: $index-navbar;
	width: 100%;

	display: grid;
	grid-template-columns: auto 1fr;
	grid-gap: $page-spacing;
	align-items: center;
	justify-items: flex-end;
	padding: $menu-padding-top $menu-padding-sides;
	height: auto;

	.logo-container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		z-index: $index-navbar-logo;

		&:after {
			content: unset;
		}

		.icon-logo {
			max-width: 40px;
			height: auto;
			margin-right: 0.5em;
		}

		p {
			margin: 0;
			font-size: 1.3rem;
			font-family: $font-mirador-bold;
			font-weight: 600;
			line-height: 1;

			@include responsive($breakpoint-mobile) {
				display: none;
			}
		}
	}

	.logo-mobile {
		max-width: 40px;
		height: auto;
	}

	.hamburger-menu {
		display: flex;
		align-items: center;
		&.hidden {
			display: none;
		}
	}

	.hamburger-menu,
	.close-menu {
		@include active-state {
			cursor: pointer;
			color: $color-blue-mid;
		}
		@include responsive($breakpoint-tablet, min) {
			display: none;
		}
	}

	.menu-links {
		@include responsive($breakpoint-tablet, min) {
			flex-direction: row;
			gap: $item-gap;
			align-items: center;
			font-size: 0.9rem;
			display: flex;
		}
		@include responsive($breakpoint-tablet) {
			&:not(.show) {
				display: none;
			}
			position: fixed;
			top: 0;
			left: 0;
			height: 100dvh;
			width: 100%;
			background: $color-primary;

			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: $page-spacing;

			font-size: 1.2rem;

			> a {
				margin: $menu-padding-top * 0.25 $menu-padding-sides;
			}

			.close-menu {
				height: var(--navbar-height_approx);
				padding: $menu-padding-top $menu-padding-sides;
				align-self: flex-end;
			}

			.mobile-spacer {
				flex-grow: 1;
			}
		}
		@include responsive($breakpoint-mobile) {
			font-size: 1rem;
		}
	}

	$search-icon-size: 1.5rem;
	.toggle-search {
		@include active-state {
			cursor: pointer;
			&:before {
				background: darken($search-bar-bg, 10%);
			}
		}
		svg {
			width: $search-icon-size;
			height: $search-icon-size;
		}
		&:before {
			background: $search-bar-bg;
			z-index: -1;
			position: absolute;
			height: 100%;
			top: 0;
			right: 0;
			width: $search-icon-size + $menu-padding-sides + $item-gap * 0.5;
		}
	}
	.search-form {
		background: $search-bar-bg;
		padding: $menu-padding-top $menu-padding-sides;
		margin: 0;

		p {
			margin: 0;
			margin-bottom: 0.5em;
			font-size: 0.85em;
		}

		input {
			margin: 0;
			margin-right: $item-gap * 0.25;
			width: 300px;
			border-color: $color-text-light;
			color: $color-text-light;
			&::placeholder {
				color: $color-text-light;
				opacity: 0.5;
			}
		}
		button {
			vertical-align: middle;
			svg {
				width: $search-icon-size;
				height: $search-icon-size;
			}
		}
	}

	// Desktop styling
	@include responsive($breakpoint-tablet, min) {
		.search-form {
			display: none;
			position: absolute;
			top: 100%;
			right: 0;
			z-index: $index-above-navbar;
		}
		.hidden-on-desktop {
			display: none;
		}
	}

	// Mobile/tablet styling for the search form
	@include responsive($breakpoint-tablet) {
		.desktop-only {
			display: none;
		}
		.toggle-search {
			display: none;
			background: $search-bar-bg;
			padding: $menu-padding-top * 2 $menu-padding-sides;
			align-self: stretch;
		}

		.search-form {
			align-self: stretch;
			padding-bottom: $page-spacing;
			display: flex;
			align-content: center;
			flex-wrap: wrap;
			p {
				flex-basis: 100%;
				font-size: 1em;
			}
			input {
				flex-grow: 1;
				width: auto;
				font-size: 16px;
			}
		}
	}

	// If you change any part of this, make sure you check the --navbar-height_approx variable in the general file is still accurate
	@include responsive(45rem, max, height) {
		padding: 1rem 1.2rem;
	}
}
