#footer-container {
	padding: 0;
}

.endcap {
	text-align: center;
	padding: $page-spacing * 0.5 $page-spacing;
	img {
		width: auto;
		height: auto;
		max-height: 50px;
		margin: 0 $page-spacing * 0.5;
	}
}

footer {
	--logo-size: 70px;

	border-top: 2px solid $color-white;

	padding: $page-spacing $page-spacing * 1.5;

	display: flex;
	flex-wrap: nowrap;
	gap: $page-spacing * 4;

	p,
	a {
		font-size: 0.85rem;
	}

	// Left column
	.program-logo {
		align-items: flex-start;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		flex-direction: column;
		img {
			height: calc(var(--logo-size) * 0.75);
			width: auto;
		}

		p {
			font-family: $font-display-bold-family;
			font-weight: $font-display-bold-weight;
			letter-spacing: $font-body-bold-spacing;
			font-size: 1.15rem;
			margin-bottom: 0;
			margin-top: 1.5em;
			line-height: 1.15em;
		}
	}

	// Central column
	.footer-links {
		flex-grow: 1;

		display: grid;
		grid-template-rows: auto auto auto;
		grid-column-gap: $page-spacing * 3;

		justify-items: flex-start;
		align-items: center;
		justify-content: flex-start;
		align-content: space-around;
		grid-auto-flow: column;
	}

	// Right column
	.drpam-logo {
		text-align: right;

		display: grid;
		grid-template-rows: 1fr auto auto;

		img {
			height: var(--logo-size);
			width: auto;
			margin-left: auto;
			display: block;
			margin-bottom: 1rem;
			align-self: center;
		}
		p {
			margin: 0;
			line-height: 1.65em;
		}
	}
	@include responsive(85rem) {
		padding: $page-spacing $page-spacing;
		gap: $page-spacing * 2.5;
		--logo-size: 45px;

		.program-logo {
			p {
				font-size: 1.25rem;
				margin-top: 0.75em;
			}
		}

		.footer-links {
			grid-column-gap: $page-spacing * 1.5;
			align-content: center;
			row-gap: $page-spacing * 0.6;
		}
	}
	@include responsive(70rem) {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-template-rows: auto auto;
		grid-template-areas:
			'logo links'
			'dr-pam links';
		grid-row-gap: $page-spacing * 1.5;

		.program-logo {
			grid-area: logo;
			img {
				height: var(--logo-size);
			}
		}
		.footer-links {
			grid-area: links;

			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-end;

			a {
				text-align: right;
				&:after {
					right: 0;
					left: unset;
				}
			}
		}
		.drpam-logo {
			grid-area: dr-pam;
			text-align: left;
			img {
				margin-left: 0;
				margin-right: auto;
			}
		}
	}
	@include responsive(30rem) {
		display: block;

		.program-logo,
		.drpam-logo {
			&:after {
				content: unset;
			}
		}
		.program-logo {
			text-align: center;
			row-gap: 1rem;
			align-items: center;
			p {
				margin-top: 0;
			}
		}
		.footer-links {
			align-items: center;
			margin: $page-spacing * 2 0;
			row-gap: $page-spacing * 0.65;
		}
		.drpam-logo {
			text-align: center;
			img {
				margin: 0 auto 1rem;
			}
		}
	}
}
